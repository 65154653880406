<template>
  <h1>Skabeloner {{ agreementName }}</h1>
  <template v-if="templates?.length">
    <v-card v-if="templates.length === 0">
      <v-btn @click="createFirstVersion()" class="primary-button mx-2 my-2">Opret første version</v-btn>
    </v-card>

    <AdminTemplate
      v-for="templateInfo in templates"
      :key="templateInfo.id"
      :templateInfo
      :agreementName
      @updated="load()"
    />
  </template>
  <LoadingSpinner :visible="isLoading" />
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { LoadingSpinner } from '@dims/components';
import templateServices from '@/components/Templates/templateServices';
import AdminTemplate from './AdminTemplate.vue';
import { AdminTemplateHistory } from '@/models/AdminQuestionnaireTemplate';

const { agreementName } = defineProps<{ agreementName: string }>();
const templates = ref<AdminTemplateHistory[] | null>(null);
const isLoading = ref(false);

async function load() {
  isLoading.value = true;
  try {
    templates.value = await templateServices.getTemplates(agreementName);
  } finally {
    isLoading.value = false;
  }
}
void load();

async function createFirstVersion() {
  await templateServices.createFirstDimsTemplateVersion(agreementName);

  await load();
}

</script>
