<template>
  <div class="my-4">
    <AdminTemplate v-if="adminTemplate" :templateInfo="adminTemplate" @updated="load" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import AdminTemplate from './AdminTemplate.vue';
import templateServices from '@/components/Templates/templateServices';
import { AdminTemplateHistory } from '@/models/AdminQuestionnaireTemplate';

const { adminTemplateId } = defineProps<{ adminTemplateId: string }>();
const isLoading = ref(false);
const adminTemplate = ref<AdminTemplateHistory>();

async function load() {
  isLoading.value = true;
  try {
    adminTemplate.value = await templateServices.getAdminTemplate(adminTemplateId);
  } finally {
    isLoading.value = false;
  }
}
void load();

</script>
