<template>
  <v-app-bar
    color="secondary"
    elevation="0"
  >
    <v-container fluid>
      <v-row class="pa-4">
        <div class="d-flex align-center">
          <div v-if="environmentTitle" class="environment-title">
            {{ environmentTitle }}
          </div>
          <a href="/">
            <img
              alt="SKI-logo"
              width="80"
              cover
              src="/img/logo-white.svg"
            >
          </a>
          <router-link to="/">
            <h1 class="ml-2 mt-0 mr-6 pl-3 top-bar-header text-white">
              DIMS Admin
            </h1>
          </router-link>
          <router-link
            to="/agreements"
            class="mx-3 mt-1 pa-2 hidden-md-and-down menu-item text-white"
          >
            <div>
              DIMS Formularværktøj
            </div>
          </router-link>
          <router-link
            to="/hot"
            class="mx-3 mt-1 pa-2 hidden-md-and-down menu-item text-white"
          >
            <div>
              HOT
            </div>
          </router-link>
          <router-link
            v-if="showToolsMenuItem"
            :to="{ name: 'Tools' }"
            class="mx-3 mt-1 pa-2 hidden-md-and-down menu-item text-white"
          >Udvikling</router-link>
        </div>
        <v-spacer></v-spacer>
        <ChatDialog v-if="features.Chat && !store.isIncognito" />
        <div class="mr-4"></div>
        <v-menu>
          <template #activator="{ props }">
            <v-btn
              v-if="!store.isIncognito"
              variant="text"
              size="x-large"
              class="px-2 bg-canvas hidden-md-and-down"
              v-bind="props"
            >
              <img
                class="ml-0"
                height="25"
                src="/img/user_icon_green.svg"
                alt="User avatar"
              />
              <div class="ml-3 text-primary">
                {{ userRealName }}
              </div>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="logOut" prepend-icon="mdi-logout">
              <v-list-item-title>Log ud</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn
          variant="text"
          v-if="store.isIncognito"
          href="/login"
          class="mr-n3 pr-4 py-6 hidden-md-and-down"
        >
          LOG IND
          <v-img
            height="14"
            cover
            class="pl-1"
            src="/img/arrow_white.svg"
          ></v-img>
        </v-btn>
        <v-menu>
          <template #activator="{ props }">
            <v-btn
              class="hidden-lg-and-up"
              v-bind="props"
              icon="mdi-menu"
            />
          </template>
          <v-list>
            <v-list-item v-for="(item, i) in pages.filter(p=> p.enabled)" :key="i" :to="item.path">
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item v-if="!store.isIncognito" prepend-icon="mdi-logout" @click="logOut">
              <v-list-item-title>Log ud</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="store.isIncognito" to="/login">
              <template #prepend>
                <v-img cover src="@/assets/arrow.svg" height="20"></v-img>
              </template>

              <v-list-item-title>LOG IND</v-list-item-title>

            </v-list-item>
          </v-list>
        </v-menu>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { ChatDialog, useConfigStore, useFeatures } from '@dims/components';
import { useStore } from '@/store/store';

interface Page {
  path: string;
  text: string;
  enabled: boolean;
}

const features = useFeatures();
const store = useStore();

const showToolsMenuItem = computed(() => useConfigStore().configuration.environment === 'Local');

const pages: Page[] = [
  {
    path: '/agreements',
    text: 'DIMS Formularværktøj',
    enabled: true,
  },
  {
    path: '/hot',
    text: 'HOT Formularværktøj',
    enabled: true,
  },
  {
    path: '/tools',
    text: 'Developer tools',
    enabled: showToolsMenuItem.value,
  },
];

const environmentTitle = computed(() => useConfigStore().configuration.environment);

const userRealName = computed(() => {
  if (store.user) {
    return store.user.realname;
  }
  return undefined;
});

async function logOut() {
  await store.logoutAction();
  document.location.href = '/';
}

</script>

<style scoped>
.d-flex .align-center {
  display: flex;
  align-items: baseline;
}

img {
  margin-left: 10px;
}

.personal-info-container {
  margin-left: 10px;
}

.top-bar-header {
  font-size: 1.3rem;
}

a {
  text-decoration: none;
}

:deep(div.v-toolbar__extension) {
  background-color: rgb(var(--v-darkGreen-base));
}

.menu-item:hover {
  border-bottom: 2px solid white;
}

.v-btn--active.no-active::before {
  opacity: 0 !important;
}

.environment-title {
  margin-right: 10px;
  padding: 4px;
  border: 3px dashed orangered;
  color: orangered;
  font-weight: bolder;
  text-transform: uppercase;
}

:deep(.v-toolbar__content) {
  padding: 0 !important;
}

</style>
