import { DeepQuestionnaire, DeepQuestionnaireTemplate, PushDeepAnswer, useBackend, Attachment, PricePointQuestionsConfiguration } from '@dims/components';
import { WordTemplate } from '@/models';
import { DeepQuestionnaireTemplateSummary } from './DeepQuestionnaireTemplateSummary';
import { DeepQuestionnaireTemplateNote } from '@/models/DeepQuestionnaireTemplateNote';
import { DeepQuestionnaireTemplateWithChanges } from './DeepQuestionnaireTemplateWithChanges';
import { TemplateBackupHistoryEntry } from './TemplateBackupHistoryEntry';
import { AdminQuestionnaireTemplate, AdminTemplateHistory } from '@/models/AdminQuestionnaireTemplate';

export default {
  getApi() {
    return useBackend().getApi();
  },

  async getTemplates(agreementName: string) {
    const response = await this.getApi().get<AdminTemplateHistory[]>(
      `deep-questionnaire-templates-info/${agreementName}`,
    );
    return response.data;
  },

  async getAdminTemplate(adminTemplateId: string) {
    const response = await this.getApi().get<AdminTemplateHistory>(
      `deep-questionnaire-template-info/${adminTemplateId}`,
    );
    return response.data;
  },

  async updateAdminTemplate(adminTemplateId: string, patch: { name: string }) {
    const response = await this.getApi().patch<AdminTemplateHistory>(
      `deep-questionnaire-template-info/${adminTemplateId}`,
      JSON.stringify(patch),
    );
    return response.data;
  },

  async getAllTemplates() {
    const response = await this.getApi().get<DeepQuestionnaireTemplateSummary[]>(
      `deep-questionnaire-templates`,
    );
    return response.data;
  },

  async getTemplateWithChanges(templateId: string) {
    const response = await this.getApi().get<DeepQuestionnaireTemplateWithChanges>(
      `deep-questionnaire-template/${templateId}`,
    );
    return response.data;
  },

  async updateTemplate(
    templateId: string,
    template: DeepQuestionnaireTemplate,
  ) {
    const response = await this.getApi().post<DeepQuestionnaireTemplateWithChanges>(
      `deep-questionnaire-template/${templateId}`,
      JSON.stringify(template),
    );
    return response.data;
  },

  async getTemplateNotes(
    templateId: string,
  ) {
    const response = await this.getApi().get<DeepQuestionnaireTemplateNote[]>(
      `deep-questionnaire-template/${templateId}/note`,
    );
    return response.data;
  },

  async addTemplateNote(
    templateId: string,
    note: string,
  ) {
    const response = await this.getApi().post<DeepQuestionnaireTemplate>(
      `deep-questionnaire-template/${templateId}/note`,
      JSON.stringify(note),
    );
    return response.data;
  },

  async deleteTemplate(templateId: string) {
    await this.getApi().delete(
      `deep-questionnaire-template/${templateId}`,
    );
  },

  async copyToEnvironment(templateId: string, targetEnvironment: string) {
    const response = await this.getApi().post<{ status: string }>(
      `deep-questionnaire-template/${templateId}/export-to-environment`,
      JSON.stringify({ targetEnvironment }),
    );
    return response.data;
  },

  async exportToPackage(templateId: string) {
    const response = await this.getApi().get<Blob>(
      `deep-questionnaire-template/${templateId}/export-to-package`,
      { responseType: 'blob' },
    );
    return response.data;
  },

  async createNewTemplateVersion(templateId: string) {
    await this.getApi().post(
      `deep-questionnaire-template/${templateId}/version`,
    );
  },

  async createFirstDimsTemplateVersion(agreementName: string) {
    await this.getApi().post(
        `deep-questionnaire-template/${agreementName}/first-version`,
    );
  },

  async activateTemplateVersion(templateId: string, applyToDrafts = false, publishedNote = '') {
    await this.getApi().post(
      `deep-questionnaire-template/${templateId}/activate`,
      JSON.stringify({ applyToDrafts, publishedNote }),
    );
  },

  async getWordTemplates(template: DeepQuestionnaireTemplate) {
    const response = await this.getApi().get<WordTemplate[]>(
      `deep-questionnaire-template/${template.id}/word-templates`,
    );
    return response.data;
  },

  async createNewWordTemplate(
    template: DeepQuestionnaireTemplate,
    newTemplateFile: File,
    newTemplateName: string,
  ) {
    const data = new FormData();
    data.append('file', newTemplateFile);
    data.append('name', newTemplateName);
    await this.getApi().post(`deep-questionnaire-template/${template.id}/word-templates`, data);
  },

  async saveVersion(info: WordTemplate, file: File) {
    const data = new FormData();
    data.append('file', file);
    await this.getApi().put(`word-templates/${info.id}/file`, data);
  },

  async downloadFile(version: WordTemplate) {
    const response = await this.getApi().get<Blob>(
      `word-template-versions/${version.id}/file`,
      { responseType: 'blob' },
    );
    return response.data;
  },

  async downloadFiles(templateId: string) {
    const response = await this.getApi().get<Blob>(
      `word-template-versions/${templateId}/files`,
      { responseType: 'blob' },
    );
    return response.data;
  },

  async downloadWordDummy(version: WordTemplate) {
    const response = await this.getApi().get<Blob>(
      `word-template-versions/${version.id}/dummy`,
      { responseType: 'blob' },
    );
    return response.data;
  },

  async getDummyWordHtmlPreview(version: WordTemplate) {
    const response = await this.getApi().get<string>(`word-template-versions/${version.id}/dummy/preview`);
    return response.data;
  },

  async getWordHtmlPreview(version: WordTemplate, answers: PushDeepAnswer[]) {
    const response = await this.getApi().post<string>(
      `word-template-versions/${version.id}/preview`,
      answers,
    );
    return response.data;
  },

  async updateWordTemplateRecord(info: WordTemplate) {
    await this.getApi().put(`word-templates/${info.id}`, info);
  },

  async deleteWordTemplate(info: WordTemplate) {
    await this.getApi().delete(`word-templates/${info.id}`);
  },

  async getPreview(templateId: string, answers: PushDeepAnswer[]) {
    const response = await this.getApi().post<DeepQuestionnaire>(
      `deep-questionnaire-template/${templateId}/questionnaire-preview`,
      answers,
    );
    return response.data;
  },

  async excelExportQuestionnaire(templateId: string) {
    const response = await this.getApi().get<Blob>(
      `deep-questionnaire-template/${templateId}/excel-export`,
      { responseType: 'blob' },
    );

    return response.data;
  },

  async excelExportList(templateInfoId: string) {
    const response = await this.getApi().get<Blob>(
      `deep-questionnaire-templates-info/${templateInfoId}/excel-export`,
      { responseType: 'blob' },
    );

    return response.data;
  },

  async uploadFile(
    templateId: string,
    questionId: string,
    file: File,
  ): Promise<Attachment[]> {
    const data = new FormData();
    data.append('file', file);
    const response = await this.getApi().put<Attachment[]>(`deep-questionnaire-template/${templateId}/${questionId}/files`, data);
    return response.data;
  },

  async downloadSKIFile(
    templateId: string,
    questionId: string,
    filename: string,
  ): Promise<Blob> {
    const response = await this.getApi().get<Blob>(
      `deep-questionnaire-template/${templateId}/${questionId}/files/${encodeURIComponent(filename)}`,
      { responseType: 'blob' },
    );
    return response.data;
  },

  async deleteFile(
    templateId: string,
    questionId: string,
    filename: string,
  ): Promise<Attachment[]> {
    const response = await this.getApi().delete<Attachment[]>(
      `deep-questionnaire-template/${templateId}/${questionId}/files/${encodeURIComponent(filename)}`,
    );
    return response.data;
  },

  async getPricePointQuestionConfiguration(configurationId: string) {
    const response = await this.getApi().get<PricePointQuestionsConfiguration>(
      `pricepointquestions-configuration/${configurationId}`,
    );
    return response.data;
  },

  async getPricePointQuestionConfigurationForAgreement(agreementName: string) {
    const response = await this.getApi().get<PricePointQuestionsConfiguration>(
      `pricepointquestions-configuration-for-agreement/${agreementName}`,
    );
    return response.data;
  },

  async getTemplateBackup(backupId: string) {
    const response = await this.getApi().get<DeepQuestionnaireTemplate>(
      `deep-questionnaire-template-backup/${backupId}`,
    );
    return response.data;
  },
  async getTemplateBackupHistory(templateId: string) {
    const response = await this.getApi().get<TemplateBackupHistoryEntry[]>(
      `deep-questionnaire-template-history/${templateId}`,
    );
    return response.data;
  },

  async getTemplatesForApplication(applicationName: string) {
    const response = await this.getApi().get<AdminQuestionnaireTemplate[]>(
      `deep-questionnaire-templates-info/application/${applicationName}`,
    );
    return response.data;
  },

  async createFirstTemplateVersion(applicationName: string, templateName: string) {
    await this.getApi().post<AdminQuestionnaireTemplate[]>(
      `deep-questionnaire-templates-info/application/${applicationName}/first-version`,
      JSON.stringify({ name: templateName }),
    );
  }
};
