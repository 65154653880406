<template>
  <v-dialog width="90%" v-model="dialog">
    <template #activator="{ props }">
      <v-btn class="ml-2 primary-button-mini" v-bind="props"><v-icon>mdi-publish</v-icon>Offentliggør ny version</v-btn>
    </template>
    <v-card title="Offentliggør ny version af skema">
      <v-card-text>
        <p>Nye indkøb indkøb vil bliver oprettet ud fra denne version</p>
        <p v-if="templateChangesCount > 1">{{ `Der er ${templateChangesCount} ændringer ifht. den nuværende offentliggjorte version${templateMovesText}` }}</p>
        <p v-else-if="templateChangesCount === 1">{{ `Der er ${templateChangesCount} ændring ifht. den nuværende offentliggjorte version${templateMovesText}` }}</p>
        <p v-else>Der er ingen ændringer ifht. den nuværende offentliggjorte version</p>
        <p v-if="questionsAddedAndRemoved > 0">{{ `Desuden blev ${questionsAddedAndRemoved} spørgsmål tilføjet og efterfølgende fjernet igen` }}</p>
        <v-radio-group v-model="updateCurrentDrafts">
          <v-radio :value="false" label="Eksisterede kladder påvirkes ikke" />
          <v-radio :value="true" label="Opdater eksisterende indkøbskladder til at følge den nye version" />
          <v-alert
            v-if="updateCurrentDrafts"
            class="ml-8"
            variant="flat"
            type="warning"
            text="Kan medføre tab af data for kunden. Hvis f.eks. et spørgsmål er slettet i den nye version, så vil spørgsmålet forsvinde fra indkøbskladden, også selvom kunden allerede har udfyldt et svar."
          >
          </v-alert>
        </v-radio-group>
        <v-textarea
          v-model="publishedNote"
          variant="outlined"
          density="compact"
          label="Release note"
          class="monospace"
        >
        </v-textarea>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn @click="onCancel()" class="ml-2 secondary-button">Fortryd</v-btn>
        <v-btn @click="activateDraft()" class="ml-2 primary-button">
          Aktiver
        </v-btn>
      </v-card-actions>
    </v-card>
    <LoadingSpinner :visible="isLoading" />
  </v-dialog>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { LoadingSpinner } from '@dims/components';
import templateServices from '@/components/Templates/templateServices';
import { DeepQuestionnaireTemplateSummary } from '@/components/Templates/DeepQuestionnaireTemplateSummary';
import { useStore } from '@/store/store';

const emit = defineEmits<{ updated: [] }>();
const { draft } = defineProps<{ draft: DeepQuestionnaireTemplateSummary }>();
const dialog = ref(false);
const updateCurrentDrafts = ref(false);
const publishedNote = ref('');
const isLoading = ref(false);
const store = useStore();
const templateChangesCount = ref(0);
const templateMovesText = ref('');
const questionsAddedAndRemoved = ref(0);

async function load() {
  isLoading.value = true;

  const templateWithChanges = await templateServices.getTemplateWithChanges(draft.id);

  const questionsRemoved = templateWithChanges.changes.filter((c) => c.change === 'QuestionRemoved');
  const questionsAdded = templateWithChanges.changes.filter((c) => c.change === 'QuestionAdded');

  questionsRemoved.forEach((c) => {
    if (questionsAdded.filter((a) => a.questionDefinitionId === c.questionDefinitionId).length > 0) {
      questionsAddedAndRemoved.value += 1;
    }
  });

  templateChangesCount.value = templateWithChanges.changes.filter((c) => c.change !== 'SubquestionChanges').length - (questionsAddedAndRemoved.value * 2); // * 2 for both add and remove

  const templateMovesCount = templateWithChanges.changes.filter((c) => c.change === 'Moved').length;

  if (templateMovesCount > 0) {
    templateMovesText.value = templateMovesCount === 1 ? ' (heraf 1 flytning)' : ` (heraf ${templateMovesCount} flytninger)`;
  }

  isLoading.value = false;
}
void load();

function onCancel() {
  dialog.value = false;
}

async function activateDraft() {
  try {
    isLoading.value = true;
    await templateServices.activateTemplateVersion(draft.id, updateCurrentDrafts.value, publishedNote.value);
  } catch (error) {
    store.showSnackbarError('Aktivering fejlede', error);
  } finally {
    isLoading.value = false;
  }
  emit('updated');
}

</script>
