<template>
  <h1>Developer Tools</h1>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="4" v-for="tool in tools" :key="tool.key">
        <v-card class="my-4">
          <v-card-title>
            {{ tool.name }}
          </v-card-title>
          <v-card-text>
            {{ tool.description }}
          </v-card-text>
          <v-card-actions>
            <ArrowButton @click="execute(tool.key)">Execute</ArrowButton>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <pre class="console"><div v-for="(line, ix) in log" :key="ix">{{ line }}</div>
  </pre>
</template>

<script lang="ts" setup>
import { ArrowButton, useBackend } from '@dims/components';
import { ref } from 'vue';

interface Tool {
  key: string;
  name: string;
  description: string;
}

const api = useBackend().getApi();
const tools = ref<Tool[]>();
const log = ref<string[]>([]);
async function load() {
  tools.value = (await api.get<Tool[]>('design-time/operations')).data;
  log.value.push('Ready.');
}
void load();

async function execute(toolKey: string) {
  log.value.push(`Executing ${toolKey}`);
  const msg = (await api.post<string[]>(`design-time/operations/${toolKey}`)).data;
  log.value.push(...msg);
}

</script>
<style scoped>
.console {
  padding: 4px;
  font-family: monospace;
  background-color: black;
  color: white;
}
</style>
