<template>
  <v-dialog width="800" v-model="dialog">
    <v-card>
      <v-card-text>
        <b>
          <p v-if="crossValidationRule == 'MinValue'">
            Vælg krydsvalidering for minimum
          </p>
          <p v-else-if="crossValidationRule == 'MaxValue'">
            Vælg krydsvalidering for maksimum
          </p>
        </b>
      </v-card-text>
      <v-radio-group v-model="selectedSource">
        <v-radio
          v-for="(source, index) in filterForCrossValidationQuestions(template.questions, question.questionDefinitionId)"
          :key="index"
          :value="source.questionDefinitionId"
          class="mb-3"
        >
          <template #label>{{ source.title }}<br />{{ source.questionDefinitionId }}</template>
        </v-radio>
      </v-radio-group>
      <v-card-actions>
        <v-btn @click="onCancel()">Annuller</v-btn>
        <v-btn class="ml-2 primary-button-mini" @click="onOk()">Gem</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { QuestionDefinition, DeepQuestionnaireTemplate } from '@dims/components';
import { filterForCrossValidationQuestions } from '@/utils';

const emit = defineEmits<{ confirmed: [crossValidationRule: string, questionDefinitionId: string], cancelled: [] }>();
const { template, question, crossValidationRule, initialSelectedSource } =
  defineProps<{ template: DeepQuestionnaireTemplate, question: QuestionDefinition, crossValidationRule: string, initialSelectedSource: string }>();

const dialog = ref(false);
const selectedSource = ref(initialSelectedSource);

function onCancel() {
  emit('cancelled');
}

function onOk() {
  const sourceQuestion = filterForCrossValidationQuestions(template.questions, question.questionDefinitionId).find(q => q.questionDefinitionId === selectedSource.value);

  if (sourceQuestion !== undefined) { // Since it's radio buttons, sourceQuestion should always be defined
    emit('confirmed', crossValidationRule, sourceQuestion.questionDefinitionId);
  }
}

</script>
