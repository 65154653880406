<template>
  <v-list :items="templates" class="my-4">
    <template #subtitle="{ item }">
      <v-btn prepend-icon="mdi-text-box-edit-outline" variant="outlined" @click="showAdminTemplate(item.id)">{{ item.name }}</v-btn>
    </template>
  </v-list>
  <v-dialog>
    <template #activator="{ props }">
      <ArrowButton v-bind="props" class="my-2">Ny formular</ArrowButton>
    </template>
    <v-card>
      <v-card-title>Ny formular</v-card-title>
      <v-card-text>
        <v-form v-model="valid">
          <v-text-field label="Navn" :model-value="name" required />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <ArrowButton :disabled="!valid" @click="createTemplate">Opret</ArrowButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog :model-value="showTemplateDialog">
    <v-sheet>
      <v-btn icon="mdi-close" class="float-right" @click="showTemplateDialog = false" />
      <AdminTemplate v-if="templateInfo" :templateInfo @updated="load" />
    </v-sheet>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import templateServices from '@/components/Templates/templateServices';
import { AdminQuestionnaireTemplate, AdminTemplateHistory } from '@/models/AdminQuestionnaireTemplate';
import { ArrowButton } from '@dims/components';
import AdminTemplate from './AdminTemplate.vue';

const templates = ref<AdminQuestionnaireTemplate[]>([]);
const templateInfo = ref<AdminTemplateHistory>();
const showTemplateDialog = ref(false);
const isLoading = ref(false);
const name = ref('');
const valid = ref(false);

async function load() {
  isLoading.value = true;
  templates.value = await templateServices.getTemplatesForApplication('HOT');
  isLoading.value = false;
}
void load();

async function createTemplate() {
  isLoading.value = true;
  try {
    await templateServices.createFirstTemplateVersion('HOT', name.value);
  } finally {
    isLoading.value = false;
  }
}

async function showAdminTemplate(adminTemplateId: string) {
  showTemplateDialog.value = false;
  isLoading.value = true;
  try {
    templateInfo.value = await templateServices.getAdminTemplate(adminTemplateId);
    showTemplateDialog.value = true;
  } finally {
    isLoading.value = false;
  }
}

</script>
